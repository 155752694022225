<template>
  <el-dialog
      v-bind="$attrs"
      width="75%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <div class="searchView pageView">
      <el-input placeholder="请输入关键字" v-model="keyWord">
        <el-select style="width: 130px" v-model="couponStatus" slot="prepend" placeholder="选择种类">
        </el-select>
        <el-button slot="append">搜索</el-button>
      </el-input>
    </div>
    <el-table class="pageView" size="medium" border="true" fit="true" :data="tableList" show-header="true">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="coupon_info"
          label="优惠券信息">
      </el-table-column>
      <el-table-column
          prop="extra_info"
          label="附加信息">
      </el-table-column>
    </el-table>
    <div class="pageView paginationView">
      <el-pagination layout="prev, pager, next" :total="50" />
    </div>
    <div slot="footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "couponSelect",
  data(){
    return {
      keyWord:'',
      couponStatus:'',
      tableList:[],
    }
  },
  methods:{
    onOpen() {
    },
    onClose() {
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.$emit('confirm', {

      })
      this.close()
    }
  }
}
</script>

<style scoped>
.searchView {
  display: flex;
}
.pageView {
  margin-top: 15px;
}
.searchItem ~ .searchItem {
  margin-left: 10px;
}
.paginationView {
  display: flex;
  justify-content: flex-end;
}
</style>