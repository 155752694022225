<template>
  <div>
    <el-button @click="showPop = true">弹窗</el-button>
    <el-button @click="showSelect = true">文件选择器</el-button>
    <el-button @click="showCouponSelect = true">优惠券选择器</el-button>
    <el-button @click="uploadPicture('flagImg')">图片裁剪</el-button>
    <PopView :visible.sync="showPop" chooseGoods title="标题"></PopView>
    <fileSelect :visible.sync="showSelect" state="file" title="标题" @confirm="confirmHandle"></fileSelect>
    <couponSelect :visible.sync="showCouponSelect" title="优惠券选择"></couponSelect>
    <cropperImg :visible.sync="cropperModel" :Name="cropperName"
                @uploadImgSuccess = "handleUploadSuccess"
                ref="child"></cropperImg>
    <div style="margin-top: 10px">
        <el-button @click="fuc('https://api.chipdatas.com/tools/cf/dev')">开发模式</el-button>
        <el-button @click="fuc('https://api.chipdatas.com/tools/cf/prod')">生产模式</el-button>
        <el-button @click="fuc('https://api.chipdatas.com/tools/cf/attack')">被攻击模式</el-button>
        <el-button @click="fuc('https://api.chipdatas.com/tools/cf/normal')">解除攻击模式</el-button>
        <el-button @click="fuc('https://api.chipdatas.com/tools/cf/clear')">清理缓存</el-button>
    </div>
  </div>
</template>

<script>
import PopView from "@/views/components/popView";
import fileSelect from "@/views/components/fileSelect";
import couponSelect from "@/views/components/couponSelect";
import cropperImg from "@/views/components/cropperImg";

import {apiAddTempGoods} from "@/request/api";
import axios from "axios";

export default {
  name: "test",
  components: {PopView, fileSelect, couponSelect,cropperImg},
  data() {
    return {
      showPop: false,
      showSelect: false,
      showCouponSelect: false,
      cropperModel:false,
      cropperName:''
    }
  },
  methods: {
      fuc(url){
        axios.get(url).then(res => {
            this.$message.success(res.data.message)
        })
      },
    toGoodsDeatail() {
      let that = this
      apiAddTempGoods({kind: 'standard'}).then(res => {
        console.log(res);
        if (res.code == 200) {
          that.$router.push({path: "/goods/editGoods", query: {goodsId: res.data.id}});
        }
      })

    },
    confirmHandle(e) {
      console.log(e)
    },
    //封面设置
    uploadPicture(name){
      this.cropperName = name;
      this.cropperModel = true;
    },
    //图片上传成功后
    handleUploadSuccess (data){
      console.log(data)
      switch(data.name){
        case 'flagImg':
          this.formValidate.mainImage = 'http://ydfblog.cn/dfs/'+data.url;
          console.log('最终输出'+data.name)
          break;
      }
      this.cropperModel = false;
    }
  }

}
</script>

<style scoped>

</style>
